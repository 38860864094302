const BulkMenu = (props) => {
    const bulkMenuOptions = [
        'Check All',
        'Check Due',
        'Check Past Due'
    ]

    const bulkMenuItems = bulkMenuOptions.map((item, index) => {
        return (
            <li key={index} className={`popup-menu__list-item`} onClick={props.onBulkActionsClick.bind(null, item.toLowerCase())}>{item}</li>
        )
    })

  return (
    <div
      className={`popup-menu popup-menu--bulk ${props.className}`}
      id="popupMenu"
    >
      <div
        className="popup-menu__bg"
        onClick={() => props.setShowBulkMenu()}
      ></div>
      <div className="popup-menu__content">
        <ul className="popup-menu__list">
          <li className="popup-menu__list-item">Bulk Actions</li>
          {bulkMenuItems}
        </ul>
      </div>
    </div>
  );
};

export default BulkMenu;