import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import useFetch from "../../hooks/useFetch";
import {
  accountActivation,
  resendActivationEmail,
} from "../../components/util/libAPI";

import LoadingSpinner from "../../components/util/LoadingSpinner"

const Activate = () => {
  const params = useParams();
  const { id } = params;
  const [output, setOutput] = useState("");
  const { error, loading, data, sendRequest } = useFetch(accountActivation);
  const {
    error: resendError,
    loading: resendLoading,
    data: resendData,
    sendRequest: resendEmail,
  } = useFetch(resendActivationEmail);

  useEffect(() => {
    sendRequest(id);
  }, [sendRequest, id]);

  const resendEmailHandler = useCallback(() => {
    resendEmail(id);
  }, [resendEmail, id]);

  const errorState = useMemo(() => {
    return {
      success: () => (
        <div>
          <p className="u-margin-bottom-mediumsmall">
            Thank you for activating your BraapDoc account. Please login to your
            new account here:
          </p>
          <a
            href="/welcome#signup"
            className="btn btn--text btn--text-symetric"
          >
            Login
          </a>
        </div>
      ),
      "resend success": () => (
        <div>
          <p className="u-margin-bottom-small">
            We sent you the activation email to your email address. Please click the link in our email to activate your account.
          </p>
          <p className="u-margin-bottom-small">
            Note: Activation links are valid for 7 days.
          </p>
          <a
            href="/welcome"
            className="btn btn--text btn--text-symetric"
          >
            Home
          </a>
        </div>
      ),
      "Key expired": () => (
        <div>
          <p className="u-margin-bottom-small">
            Unfortunately, the link you clicked expired already. Please use the
            button below to request a new activation email.
          </p>
          <p className="u-margin-bottom-small">
            Note: Activation links are valid for 7 days.
          </p>
          <button
            onClick={resendEmailHandler}
            className="btn btn--text btn--text-symetric"
          >
            {resendLoading ? "Loading..." : "Resend Email"}
          </button>
        </div>
      ),
      "Key invalid": () => (
        <div>
          <p className="u-margin-bottom-mediumsmall">
            Oh no. We were not able to find your account. Please use the
            registration form here:
          </p>
          <a
            href="/welcome#signup"
            className="btn btn--text btn--text-symetric"
          >
            Register
          </a>
        </div>
      ),
      "Account not found": () => (
        <div>
          <p className="u-margin-bottom-mediumsmall">
            Oh no. We were not able to find your account. Please use the
            registration form here:
          </p>
          <a
            href="/welcome#signup"
            className="btn btn--text btn--text-symetric"
          >
            Register
          </a>
        </div>
      ),
      "Account already activated": () => (
        <div>
          <p className="u-margin-bottom-mediumsmall">
            Your account is active. Please login to your account here:
          </p>
          <a
            href="/welcome#signup"
            className="btn btn--text btn--text-symetric"
          >
            Login
          </a>
        </div>
      ),
      default: "Something went wrong. Please try again later",
    };
  }, [resendEmailHandler, resendLoading]);

  const getResult = useCallback(
    (displayError) => {
      const errorKey = displayError.toString().split(":")[1].trim();
      if (errorState.hasOwnProperty(errorKey)) {
        return errorState[errorKey]();
      } else {
        return <p>{errorState.default}</p>;
      }
    },
    [errorState]
  );

  useEffect(() => {
    if (error || data) {
      setOutput(getResult(error || data));
    }
    if(resendError || resendData) {
        setOutput(getResult(resendError || resendData))
    }
  }, [getResult, data, error, resendError, resendData]);

  return (
    <div className="opener">
      <div className="opener__bg-image"></div>
      <div className="opener__logo-box">
        <img src="/img/BRAAPDoc_HD2.png" alt="Logo" className="opener__logo" />
      </div>
      <div className=" opener__text-box opener__text-box--bg">
        {loading && <LoadingSpinner />}
        <h3 className="u-margin-bottom-small">Account Activation</h3>
        {output}
      </div>
    </div>
  );
};

export default Activate;
