import GetActiveVehicle from "../../util/GetActiveVehicle";

const HowTo = (props) => {
    const activeVehicle = GetActiveVehicle()

    const vehicleString = activeVehicle?.year + '+' + activeVehicle?.make + '+' + activeVehicle?.model
    const taskString = props.task.description.replace(' ', '+')
    return (
        <div className="howto">
            <h3 className="heading-tertiary task-form__heading u-margin-bottom-small">how to {props.task.description} on your {activeVehicle?.type}: </h3>
            <a
            href={`https://www.youtube.com/results?search_query=${vehicleString}+${taskString}`}
            target="_blank"
            rel="noreferrer"
          ><img src="/img/youtube.svg" alt="YouTube logo" className="logo"/></a>
        </div>
    )
}

export default HowTo