import { useEffect, useState } from "react";
import { UploadCloud, XSquare } from "react-feather";
import { useLocation } from "react-router-dom";

import useInputValidator from "../../hooks/useInputValidator";
import useFetch from "../../hooks/useFetch";
import LoadingSpinner from "../util/LoadingSpinner";
import GetActiveVehicle from "../util/GetActiveVehicle";
import { saveVehicle } from "../util/libAPI";

const EditVehicleForm = (props) => {
  const location = useLocation()
  const modalType = location.state?.type
  const [displayedVehicle, setDisplayedVehicle] = useState({});
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const activeVehicle = GetActiveVehicle();

  const {
    loading: vehicleLoading,
    error: vehicleError,
    data: vehicleData,
    sendRequest
  } = useFetch(saveVehicle);

  let loading = vehicleLoading

  useEffect(() => {
    if(activeVehicle && modalType === 'editModal'){
      setDisplayedVehicle(activeVehicle)
    } 
    if(modalType === 'addVehicleModal') {
      setDisplayedVehicle({})
    }
  }, [activeVehicle, modalType])

  useEffect(() => {
    if (
      vehicleData && 
      !vehicleLoading &&
      !vehicleError &&
      !fetchSuccess
    ) {
      
      setFetchSuccess(true)
    }
  }, [vehicleData, vehicleLoading, vehicleError, fetchSuccess])

  const {
    input: typeInput,
    inputIsValid: typeInputIsValid,
    inputHasError: typeInputHasError,
    inputClasses: typeInputClasses,
    labelClasses: typeInputLabelClasses,
    blurHandler: typeInputBlurHandler,
    changeHandler: typeInputChangeHandler,
    resetHandler: typeResetHandler,
  } = useInputValidator({
    validateData: (value) => value.trim() !== "",
    startValue: displayedVehicle.type,
  });

  const {
    input: yearInput,
    inputIsValid: yearInputIsValid,
    inputHasError: yearInputHasError,
    inputClasses: yearInputClasses,
    labelClasses: yearInputLabelClasses,
    blurHandler: yearInputBlurHandler,
    changeHandler: yearInputChangeHandler,
    resetHandler: yearResetHandler,
  } = useInputValidator({
    validateData: (value) => value.trim() !== "",
    startValue: displayedVehicle.year || 0,
  });

  const {
    input: makeInput,
    inputIsValid: makeInputIsValid,
    inputHasError: makeInputHasError,
    inputClasses: makeInputClasses,
    labelClasses: makeInputLabelClasses,
    blurHandler: makeInputBlurHandler,
    changeHandler: makeInputChangeHandler,
    resetHandler: makeResetHandler,
  } = useInputValidator({
    validateData: (value) => value.trim() !== "",
    startValue: displayedVehicle.make || "",
  });

  const {
    input: modelInput,
    inputIsValid: modelInputIsValid,
    inputHasError: modelInputHasError,
    inputClasses: modelInputClasses,
    labelClasses: modelInputLabelClasses,
    blurHandler: modelInputBlurHandler,
    changeHandler: modelInputChangeHandler,
    resetHandler: modelResetHandler,
  } = useInputValidator({
    validateData: (value) => value.trim() !== "",
    startValue: displayedVehicle.model || "",
  });

  const {
    input: odoUnitInput,
    inputIsValid: odoUnitInputIsValid,
    inputHasError: odoUnitInputHasError,
    inputClasses: odoUnitInputClasses,
    labelClasses: odoUnitInputLabelClasses,
    blurHandler: odoUnitInputBlurHandler,
    changeHandler: odoUnitInputChangeHandler,
  } = useInputValidator({
    validateData: (value) => value.trim() !== "",
    startValue: "hours",
  });

  const {
    input: odoInput,
    inputIsValid: odoInputIsValid,
    inputHasError: odoInputHasError,
    inputClasses: odoInputClasses,
    labelClasses: odoInputLabelClasses,
    blurHandler: odoInputBlurHandler,
    changeHandler: odoInputChangeHandler,
    resetHandler: odoInputResetHandler,
  } = useInputValidator({
    validateData: (value) => value > 0,
    startValue: displayedVehicle.ODO || 0,
  });

  const formHasError =
    typeInputHasError ||
    yearInputHasError ||
    makeInputHasError ||
    modelInputHasError ||
    odoUnitInputHasError ||
    odoInputHasError;

  const fileChangeHandler = (e) => {
    const uploadFile = e.target.files[0];
    if (uploadFile.size > 1024 * 1024 * 5) {
      return setFileError(true);
    }

    if (uploadFile.type !== "image/jpeg" && uploadFile.type !== "image/png"){
      return setFileError(true)
    }
    setFileError(false);
    setFile(uploadFile)
  };

  const resetHandler = (e) => {
    setFileError(false)
    sendRequest({editVehicle: false, vehicle: {}, file: null});
    setFetchSuccess(false)
    typeResetHandler();
    yearResetHandler();
    makeResetHandler();
    modelResetHandler();
    odoInputResetHandler();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    
    const formIsValid =
      typeInputIsValid &&
      yearInputIsValid &&
      makeInputIsValid &&
      modelInputIsValid &&
      odoUnitInputIsValid &&
      odoInputIsValid;

    if (!formIsValid) {
      return;
    }

    let vehicle = {
      type: typeInput,
      year: yearInput,
      make: makeInput,
      model: modelInput,
      usageUnit: odoUnitInput,
      ODO: odoInput,
    };

    if (file) {
      vehicle.hasAvatar = true;
    }
    sendRequest({editVehicle: modalType === 'editModal', vehicle, file})
    setFile(null);
    setFetchSuccess(false);
  };

  return (
    <form className="form editVehicleForm" onSubmit={submitHandler}>
      <div className="editVehicleForm__close">
        <a
          href="#vehicleActions"
          className="editVehicleForm__close-link"
          onClick={resetHandler}
        >
          <XSquare className="editVehicleForm__close-icon" />
        </a>
      </div>
      <div className="heading-tertiary editVehicleForm__heading">
        {props.editVehicle && 'Edit Vehicle'}
        {props.addVehicle && 'Add Vehicle'}
      </div>
      {vehicleError && (
        <p className="form__error-msg u-margin-bottom-small">
          {vehicleError.message}
        </p>
      )}
      {formHasError && (
        <p className="form__error-msg u-margin-bottom-small">
          Please fill out the required fields.
        </p>
      )}
      {fileError && (
        <div>
          <p className=" form__error-msg form__error-msg--fileError">Maximum allowed file size is 5MB.</p>
          <p className="form__error-msg form__error-msg--fileError u-margin-bottom-mediumsmall">
            Allowed file types are .jpg, .jpeg, and .png.
          </p>
        </div>
      )}
      <div className="form__group">
        <label
          className={`form__label editVehicleForm__label ${typeInputLabelClasses}`}
          htmlFor="type"
        >
          Type:
        </label>
        <input
          id="type"
          type="text"
          required
          value={typeInput}
          onBlur={typeInputBlurHandler}
          onChange={typeInputChangeHandler}
          placeholder={displayedVehicle.type}
          className={`form__input ${typeInputClasses}`}
        />
      </div>
      <div className="form__group">
        <label
          className={`form__label editVehicleForm__label ${yearInputLabelClasses}`}
          htmlFor="year"
        >
          Year:
        </label>
        <input
          id="year"
          type="number"
          min={0}
          step={1}
          required
          value={yearInput}
          onBlur={yearInputBlurHandler}
          onChange={yearInputChangeHandler}
          placeholder={displayedVehicle.year}
          className={`form__input ${yearInputClasses}`}
        />
      </div>
      <div className="form__group">
        <label
          className={`form__label editVehicleForm__label ${makeInputLabelClasses}`}
          htmlFor="make"
        >
          Make:
        </label>
        <input
          id="make"
          type="text"
          required
          value={makeInput}
          onBlur={makeInputBlurHandler}
          onChange={makeInputChangeHandler}
          placeholder={displayedVehicle.make}
          className={`form__input ${makeInputClasses}`}
        />
      </div>
      <div className="form__group">
        <label
          className={`form__label editVehicleForm__label ${modelInputLabelClasses}`}
          htmlFor="model"
        >
          Model:
        </label>
        <input
          id="model"
          type="text"
          required
          value={modelInput}
          onBlur={modelInputBlurHandler}
          onChange={modelInputChangeHandler}
          placeholder={displayedVehicle.model}
          className={`form__input ${modelInputClasses}`}
        />
      </div>
      {modalType === 'addVehicleModal' && (
        <div className="form__group">
          <label
            className={`form__label editVehicleForm__label ${odoUnitInputLabelClasses}`}
            htmlFor="odoUnit"
          >
            ODO Unit:
          </label>
          <select
            className={`form__input ${odoUnitInputClasses}`}
            onBlur={odoUnitInputBlurHandler}
            onChange={odoUnitInputChangeHandler}
            defaultValue="hours"
          >
            <option value="hours">Hours</option>
            <option value="miles">Miles</option>
            <option value="kilometers">Kilometers</option>
          </select>
        </div>
      )}
      <div className="form__group">
        <label
          className={`form__label editVehicleForm__label ${odoInputLabelClasses}`}
          htmlFor="odo"
        >
          ODO:
        </label>
        <input
          id="odo"
          type="number"
          min={0}
          step={1}
          required
          value={odoInput}
          onBlur={odoInputBlurHandler}
          onChange={odoInputChangeHandler}
          placeholder={displayedVehicle.ODO}
          className={`form__input ${odoInputClasses}`}
        />
      </div>
      <div className="form__group">
        <label className="form__label editVehicleForm__label">
          Avatar:
        </label>
        <label className="avatar" htmlFor="avatar"><span className="avatar-icon"><UploadCloud /></span>Upload Picture</label>
        <input
          id="avatar"
          type="file"
          className="form__input--file"
          accept="image/png, image/jpeg"
          onChange={fileChangeHandler}
        />
      </div>
      <div className="form__group">
        {!loading && !fetchSuccess && (
          <button
            type="submit"
            className="btn btn--primary"
            disabled={fileError}
          >
            Save Changes
          </button>
        )}
        {loading && <LoadingSpinner />}
        {fetchSuccess && (
          <div>
            <p className="form__success-msg u-margin-bottom-small">
              Changes saved successfully.
            </p>
            <a
              href="#vehicleActions"
              className="btn btn--text editVehicleForm__btn--text"
              onClick={resetHandler}
            >
              Close
            </a>
          </div>
        )}
      </div>
    </form>
  );
};

export default EditVehicleForm;