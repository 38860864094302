import { useEffect } from "react";
import useFetch from "../../hooks/useFetch";

import { getAllVehicles } from "../../components/util/libAPI";

import MainMenu from '../../components/menu/MainMenu'
import TaskList from "../../components/tasks/TaskList";
import Modal from "../../components/modal/Modal";
import EditVehicleForm from '../../components/forms/EditVehicleForm'
import AddTasksToManager from "../../components/tasks/AddTasksToManager";
import VehiclesGallery from "../../components/vehicles/VehiclesGallery";
import VehicleActions from "../../components/vehicles/vehicleActions/VehicleActions";
import Loading from "../Status/Loading";

const Garage = () => {
  const {
    error: vehicleError,
    loading: vehicleLoading,
    sendRequest
  } = useFetch(getAllVehicles);

  useEffect(() => {
      sendRequest()
  }, [sendRequest]);

  return (
    <main className="garage">
      <MainMenu />
      {vehicleLoading && <Loading />}
      {vehicleError && <p className="form__label--invalid">An error occured. Please try again later</p>}
      <VehiclesGallery />
      <VehicleActions />

      <section className="task-manager">
        <div className="u-center-text u-margin-bottom-small">
          <h2 className="heading-secondary" id="taskManager">
            Task Manager
          </h2>
        </div>
        <TaskList />
      </section>

      <div className="modal-container">
        <Modal modalId="editModal">
          <EditVehicleForm key={1} editVehicle={true}/>
        </Modal>
        <Modal modalId="taskModal" className='taskModal'>
          <AddTasksToManager />
        </Modal>
      </div>
    </main>
  );
};

export default Garage;
