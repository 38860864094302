import EditTaskForm from "../../forms/EditTaskForm";
import useFetch from "../../../hooks/useFetch";
import { editTask, deleteTaskFromSchedule } from "../../util/libAPI";

const Edit = (props) => {

  const {
    loading: taskLoading,
    error: taskError,
    data: taskData,
    sendRequest,
  } = useFetch(editTask);

  const {
    loading: deleteTaskLoading,
    error: deleteTaskError,
    sendRequest: deleteTask,
  } = useFetch(deleteTaskFromSchedule);

  const deleteTaskHandler = () => {
    deleteTask(props.task);
  };

  const sendHandler = (task) => {
    sendRequest({ task, vehicleId: props.vehicle._id });
  }

  const btnDef = (
    <div className="form__group u-margin-bottom-small">
      <button type="submit" className="btn btn--primary">
        Update!
      </button>
      <button
        type="button"
        onClick={deleteTaskHandler}
        className="btn--text btn--text-inline"
      >
        {deleteTaskLoading ? "Loading..." : "Delete!"}
      </button>
      {deleteTaskError && <p className="form__label--invalid">An error occured. Please try again later.</p>}
    </div>
  );

  return <EditTaskForm task={props.task} fieldsetClasses="form__fieldset--noBorder" vehicle={props.vehicle} btnDef={btnDef} taskHandler={{sendHandler,taskData,taskError,taskLoading}}/>;
};

export default Edit;