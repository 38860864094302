import { useState } from "react";
import { Menu } from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { isAuthenticated } from "../redux/slices/authSlice";

const MainMenu = () => {
  const navigate = useNavigate()
    const [menuChecked, setMenuChecked] = useState(false);
    const authenticated = useSelector(isAuthenticated);

    const toggleMenuCheckedHandler = () => {
        setMenuChecked(!menuChecked);
      };

      const logoutClickHandler = () => {
        navigate("/logout")
      }

      const aboutUsClickHandler = () => {
        navigate("/aboutUs")
      }

    return(
        <div className="menu">
        <input
          type="checkbox"
          id="menu-checkbox"
          className="menu__checkbox"
          checked={menuChecked}
          onChange={() => {}}
        ></input>
        <label
          htmlFor="menu-checkbox"
          className="menu__label"
          onClick={toggleMenuCheckedHandler}
        >
          <Menu className="menu__icon" />
        </label>
        <div className="menu__background">&nbsp;</div>
        <div className="menu__nav">
          <ul className="menu__list">
            {!authenticated && <li className="menu__item" onClick={toggleMenuCheckedHandler}>
              <a href="#signup" className="menu__link">
                Login
              </a>
            </li>}
            {!authenticated && <li className="menu__item" onClick={toggleMenuCheckedHandler}>
              <a href="#signup" className="menu__link">
                Sign Up
              </a>
            </li>}
            {authenticated && <li className="menu__item" onClick={toggleMenuCheckedHandler}>
              <a href="/garage" className="menu__link">
                Garage
              </a>
            </li>}
            <li className="menu__item" onClick={toggleMenuCheckedHandler}>
              <a href="#aboutUs" className="menu__link" onClick={aboutUsClickHandler}>
                About Us
              </a>
            </li>
            {authenticated && <li className="menu__item" onClick={toggleMenuCheckedHandler}>
              <a href="#logout" className="menu__link" onClick={logoutClickHandler}>
                Logout
              </a>
            </li>}
          </ul>
        </div>
      </div>
    )
}

export default MainMenu