import { useEffect, useState } from "react";
import { Clock } from "react-feather";

import { editVehicle } from "../../util/libAPI";
import { getActiveVehicle } from "../../redux/slices/vehicleSlice";
import useFetch from "../../../hooks/useFetch";
import { useSelector } from "react-redux";

const UpdateCard = () => {
    const [displayedVehicle, setDisplayedVehicle] = useState({})
    const reduxVehicle = useSelector((state) => getActiveVehicle(state))

    const {loading: updateLoading, error: updateError, sendRequest} = useFetch(editVehicle)

    useEffect(() => {
      if(reduxVehicle){
        setDisplayedVehicle(reduxVehicle)
      }
    },[reduxVehicle])

    const odoChangeHandler = (e) => {
        setDisplayedVehicle((prevState) => {
          return {
            ...prevState,
            ODO: parseInt(e.target.value)
          }
        })
    }

    const submitHandler = (e) => {
        e.preventDefault()
        sendRequest(displayedVehicle.ODO)
    }

  return (
    <div className="card">
      <div className="card__side card__side--front">
        <div className="card__picture">
          <Clock className="card__picture-icon" />
        </div>
        <h4 className="card__heading">
          <span className="card__heading-span card__heading-span--1">
            Update
          </span>
        </h4>
        
      </div>
      <div className="card__side card__side--back card__side--back-1">
        <div className="card__cta card__cta-1">
          <div className="card__form-box card__form-box-1">
            <form onSubmit={submitHandler}>
              <div className="card__form-group">
                <label
                  className="card__label"
                  htmlFor="updateOdoInput"
                >
                  Update ODO to:
                </label>
                <input
                  id="updateOdoInput"
                  type="number"
                  min="0"
                  step="1"
                  required
                  value={displayedVehicle?.ODO || 0}
                  onChange={odoChangeHandler}
                  className="card__input"
                />
              </div>
              <button type="submit" className="btn--action btn--white" disabled={updateLoading}>
                {updateError ? <p className="form__label--invalid">Error. Try again later.</p> : 
                  updateLoading ? "Loading..." : "Update Now!"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCard
