import ObjectId from "bson-objectid";

import reduxStore from "../redux/store/reduxStore";

import { editTask as editTaskDispatch, removeTask, bulkUpdateLastCompleted } from "../redux/slices/taskSlice";
import {
  populateStore,
  getActiveVehicle,
  editVehicle as editVehicleDispatch,
  removeVehicle as removeVehicleDispatch
} from "../redux/slices/vehicleSlice";
import { populateTasks } from "../redux/slices/taskSlice";
import getUsageUnit from "../util/getUsageUnit";

const API_URL = process.env.REACT_APP_API;

const getUserToken = () => {
  const state = reduxStore.getState();
  return state.auth.token;
};

const prepareTaskUtil = (task, hours) => {
  const activeVehicle = getActiveVehicle(reduxStore.getState());
  const unit = getUsageUnit(activeVehicle.usageUnit);
  const interval = unit === 'mls' ? parseInt(task.interval) / 30 : unit === 'km' ? parseInt(task.interval) / 50 : hours;

  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const lastUpdate = `${month}/${day}/${year}`;

  const lastCompleted = {
    hours: parseInt(hours),
    date: lastUpdate,
  };

  const updatedTask = {
    vehicleId: activeVehicle._id,
    task: {
      ...task,
      interval,
      lastCompleted,
    },
  };

  return{
    lastCompleted,
    updatedTask
  }
}

export const getAllVehicles = async () => {
  const userToken = getUserToken();
  const response = await fetch(`${API_URL}/bikes`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not update task.");
  }

  reduxStore.dispatch(populateStore(data));

  return data;
};

export const editTask = async ({ task, vehicleId }) => {
  const activeVehicle = getActiveVehicle(reduxStore.getState())
  const userToken = getUserToken();
  const unit = getUsageUnit(activeVehicle.usageUnit);
  const interval = unit === 'mls' ? parseInt(task.interval) / 30 : unit === 'km' ? parseInt(task.interval) / 50 : task.interval;
  const response = await fetch(`${API_URL}/tasks/${task._id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      description: task.description,
      interval,
    }),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not update task.");
  }

  const newTask = {
    vehicleId,
    task: {
      ...task,
      interval,
      lastCompleted: undefined,
    },
  };

  reduxStore.dispatch(editTaskDispatch(newTask));

  return 'success';
};

export const createCustomTask = async (task) => {
  const activeVehicle = getActiveVehicle(reduxStore.getState())
  const userToken = getUserToken()

  const response = await fetch(`${API_URL}/tasks/${activeVehicle._id}`,{
    method: 'POST',
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(task)
  })

  if(!response.ok){
    throw new Error("Error creating task. Please try again later")
  }

  const data = await response.json()

  return data
}

export const updateLastCompleted = async ({task, hours}) => {
  const userToken = getUserToken();

  const {lastCompleted, updatedTask} = prepareTaskUtil(task, hours)

  const response = await fetch(`${API_URL}/tasks/lastCompleted/${task._id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ lastCompleted }),
  });

  if (!response.ok) {
    throw new Error("An error occured. Please try again later.");
  }

  reduxStore.dispatch(editTaskDispatch(updatedTask));

  return 'success';
};

export const updateLastCompletedBulk = async ({tasks, hours}) => {
  const userToken = getUserToken()
  const activeVehicle = getActiveVehicle(reduxStore.getState())

  const preparedTasks = tasks.map((task) => {
    const {lastCompleted} = prepareTaskUtil(task, hours)
    return  {
      ...task,
      bike: activeVehicle._id,
      lastCompleted: [{
        ...lastCompleted
      }]
    }
  })
  
  const response = await fetch(`${API_URL}/tasks/lastCompleted`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': "application/json"
    },
    body: JSON.stringify({tasks: preparedTasks})
  })

  if(!response.ok){
    throw new Error("An error occured. Please try again later.")
  }

  reduxStore.dispatch(bulkUpdateLastCompleted(preparedTasks))

  return 'success'
}

export const fetchTasksByVehicleId = async () => {
  const activeVehicle = getActiveVehicle(reduxStore.getState());
  const userToken = getUserToken();

  const response = await fetch(`${API_URL}/tasks/${activeVehicle._id}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch tasks.");
  }

  reduxStore.dispatch(
    populateTasks({
      vehicleId: activeVehicle._id,
      tasks: data,
    })
  );

  return data;
};

export const addTaskToSchedule = async (task) => {
  const activeVehicle = getActiveVehicle(reduxStore.getState());
  const userToken = getUserToken();

  const newTask = {
    vehicleId: activeVehicle._id,
    task: {
      ...task,
      lastCompleted: [],
      _id: ObjectId().toHexString(),
    },
  };

  const response = await fetch(`${API_URL}/tasks/${newTask.vehicleId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newTask.task),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Error while adding task to schedule.");
  }

  reduxStore.dispatch(editTaskDispatch(newTask));

  return null;
};

export const editVehicle = async (ODO) => {
  const activeVehicle = getActiveVehicle(reduxStore.getState());
  const userToken = getUserToken();

  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const lastUpdate = `${month}/${day}/${year}`;

  const response = await fetch(`${API_URL}/bikes/${activeVehicle._id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ODO, lastUpdate }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Error while saving vehicle.");
  }

  reduxStore.dispatch(
    editVehicleDispatch({
      ...activeVehicle,
      ODO,
      lastUpdate,
    })
  );

  return null;
};

export const getCatalogTasks = async () => {
  const userToken = getUserToken();
  const response = await fetch(`${API_URL}/catalogtasks`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch tasks from catalog.");
  }

  return data;
};

export const getCustomTasks = async () => {
  const userToken = getUserToken();
  const response = await fetch(`${API_URL}/usertasks`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch tasks from catalog.");
  }

  return data;
}

export const saveVehicle = async ({ editVehicle, vehicle, file }) => {
  if(Object.keys(vehicle).length === 0){
    return false
  }
  
  const activeVehicle = getActiveVehicle(reduxStore.getState());
  const userToken = getUserToken();

  if (editVehicle) {
    delete vehicle.usageUnit;
  }

  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  vehicle.lastUpdate = `${month}/${day}/${year}`;

  const vehicleFetchUrl = editVehicle
    ? `${API_URL}/bikes/${activeVehicle._id}`
    : `${API_URL}/bikes`;
  const vehicleFetchMethod = editVehicle ? "PATCH" : "POST";

  const response = await fetch(vehicleFetchUrl, {
    method: vehicleFetchMethod,
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(vehicle),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      data.message ||
        "Something went while saving your vehicle. Please try again later."
    );
  }

  reduxStore.dispatch(editVehicleDispatch(data));

  if (file) {
    const id = data._id;
    const formData = new FormData();
    formData.append("vehicleAvatar", file);
    const fileResponse = await fetch(`${API_URL}/bikes/${id}/avatar`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      body: formData,
    });

    if (!fileResponse.ok) {
      throw new Error(
        "Maximum allowed file size is 5MB. Allowed file types are .jpg, .jpeg, and .png."
      );
    }
  }

  return "success";
};

export const deleteVehicle = async () => {
  const activeVehicle = getActiveVehicle(reduxStore.getState());
  const userToken = getUserToken();
  
  const response = await fetch(`${API_URL}/bikes/${activeVehicle._id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  if(!response.ok){
    throw new Error(
      "Error deleting vehicle. Please try again later"
    )
  }

  reduxStore.dispatch(removeVehicleDispatch(activeVehicle._id));

  return 'success'
};

export const userLogin = async (userObject) => {
  const response = await fetch(`${API_URL}/users/login`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userObject)
  })

  const data = await response.json()

  if(!response.ok){
    throw new Error(
      data || "Something went wrong. Please try again later."
    )
  }

  return data
}

export const deleteTaskFromSchedule = async (task) => {
  const userToken = getUserToken()
  const activeVehicle = getActiveVehicle(reduxStore.getState())
  const response = await fetch(`${API_URL}/tasks/${task._id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  })

  if(!response.ok){
    throw new Error(
      'An error occured while deleting. Please try again later.'
    )
  }

  reduxStore.dispatch(removeTask({
    vehicleId: activeVehicle._id,
    task
  }));
}

export const userSignup = async (newUser) => {
  const response = await fetch(`${API_URL}/users`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(newUser)
  })

  const data = await response.json()

  if(!response.ok){
    throw new Error(data || "Something went wrong. Please try again later.")
  }

  return 'success'
}

export const accountActivation = async (activationKey) => {
   const response = await fetch(`${API_URL}/activate/${activationKey}`,{
    method: 'POST'
   })

   const data = await response.json()

   if(!response.ok){
    throw new Error(data || "Error while activating your account. Please try again later.")
   }

   return 'data: success'
}

export const resendActivationEmail = async (activationKey) => {
  const response = await fetch(`${API_URL}/resend/${activationKey}`, {
    method: 'POST',
  })

  const data = await response.json()

   if(!response.ok){
    throw new Error(data || "An error occured. Please try again later.")
   }

   return 'data: resend success'
}

export const loginWithGoogle = async (googleIdToken) => {
  const response = await fetch(`${API_URL}/users/googleLogin`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({token: googleIdToken})
  })

  const data = await response.json()

  if(!response.ok){
    throw new Error(data | "An error occured. Please try again later.")
  }

  return data
}

export const getGoogleProfile = async (accessToken) => {
  const response = await fetch(`${API_URL}/users/getGoogleProfile`,{
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({token: accessToken})
  })

  const data = await response.json()

  if(!response.ok){
    throw new Error(data | "An error occured. Please try again later.")
  }

  return data
}

export const loginWithFacebook = async (user) => {
  const response = await fetch(`${API_URL}/users/facebookLogin`, {
    method: "POST",
    headers:{
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  })

  const data = await response.json()

  if(!response.ok){
    throw new Error(data | "An error occured. Please try again later.")
  }

  return data
}

export const logoutAll = async () => {
  const userToken = getUserToken()
  await fetch(`${API_URL}/users/logoutAll`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  })

  return null
}