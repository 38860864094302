import { useCallback, useEffect, useMemo, useState } from "react";
import { Filter, PlusSquare, Layers } from "react-feather";
import { useSelector } from "react-redux";

import useFetch from "../../hooks/useFetch";
import { getActiveVehicle } from "../redux/slices/vehicleSlice";
import { getAllTasksByVehicleId } from "../redux/slices/taskSlice";

import Task from "./Task";
import { fetchTasksByVehicleId, updateLastCompletedBulk } from "../util/libAPI";
import FilterMenu from "../menu/FilterMenu";
import BulkMenu from "../menu/BulkMenu";
import LoadingSpinner from "../util/LoadingSpinner";
import Pagination from "../util/Pagination";

const PageSize = 10;

const TaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [actionsMenuActiveId, setActionsMenuActiveId] = useState(null);
  const [filterMenuActiveId, setFilterMenuActiveId] = useState(0);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showBulkMenu, setShowBulkMenu] = useState(false);
  const [bulkActionClasses, setBulkActionClasses] = useState('');
  const vehicle = useSelector(getActiveVehicle) || {};
  const reduxTasks = useSelector((state) =>
    getAllTasksByVehicleId(state, vehicle?._id)
  );

  const {
    loading: tasksLoading,
    error: tasksError,
    sendRequest,
  } = useFetch(fetchTasksByVehicleId);

  const {
    loading: bulkActionLoading,
    error: bulkActionError,
    data: bulkActionData,
    sendRequest: sendBulkActionRequest
  } = useFetch(updateLastCompletedBulk);

  const currentTasks = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return tasks.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, tasks])

  const sort = useMemo(() => {
    return {
      "due soonest": (unsorted) =>
        unsorted.sort((taskA, taskB) => {
          const task1 =
            (taskA.lastCompleted.at(-1)?.hours || 0) +
            taskA.interval -
            vehicle.ODO;
          const task2 =
            (taskB.lastCompleted.at(-1)?.hours || 0) +
            taskB.interval -
            vehicle.ODO;
          return task1 > task2 ? 1 : -1;
        }),
      "done last": (unsorted) =>
        unsorted.sort((taskA, taskB) => {
          const date1 = taskA.lastCompleted.at(-1)
            ? taskA.lastCompleted.at(-1).hours
            : "0";
          const date2 = taskB.lastCompleted.at(-1)
            ? taskB.lastCompleted.at(-1).hours
            : "0";
          return date1 < date2 ? 1 : -1;
        }),
      "a - z": (unsorted) =>
        unsorted.sort((taskA, taskB) =>
          taskA.description.localeCompare(taskB.description)
        ),
      "z - a": (unsorted) =>
        unsorted.sort((taskA, taskB) =>
          taskB.description.localeCompare(taskA.description)
        ),
    };
  }, [vehicle.ODO]);

  const bulkActions = useMemo(() => {
    const buffer = vehicle.usageUnit === 'hours' ? 10 : 100
    return {
      'check all':      () => sendBulkActionRequest({tasks, hours: vehicle.ODO}),
      'check due':      () => {
        const dueTasksOnly = tasks.filter((task) => {
          const doneAt = task.lastCompleted?.length > 0 ? parseInt(task.lastCompleted.at(-1).hours) : 0
          return vehicle.ODO - doneAt >= task.interval && vehicle.ODO - doneAt - task.interval < buffer
        })
        sendBulkActionRequest({tasks: dueTasksOnly, hours: vehicle.ODO})
      },
      'check past due': () => {
        const pastDueTasksOnly = tasks.filter((task) => {
          const doneAt = task.lastCompleted?.length > 0 ? parseInt(task.lastCompleted.at(-1).hours) : 0
          return vehicle.ODO - doneAt >= task.interval && vehicle.ODO - doneAt - task.interval >= buffer
        })
        sendBulkActionRequest({tasks: pastDueTasksOnly, hours: vehicle.ODO})
      },
    }
  }, [sendBulkActionRequest, tasks, vehicle.ODO, vehicle.usageUnit])

  const sortClickHandler = useCallback(
    ({ criteria, id }) => {
      const unsorted = [...reduxTasks.tasks];
      const sorted = sort[criteria](unsorted);
      setTasks(sorted);
      setFilterMenuActiveId(id);
    },
    [sort, reduxTasks?.tasks]
  );

  const bulkActionsClickHandler = (criteria) => {
    bulkActions[criteria]()
  }

  useEffect(() => {
    if (reduxTasks) {
      sortClickHandler({ criteria: "due soonest", id: 0 });
    }
  }, [reduxTasks, sortClickHandler]);

  useEffect(() => {
    if(vehicle._id){
      setActionsMenuActiveId(null)
    }
  }, [vehicle._id])

  useEffect(() => {
    if (vehicle._id && vehicle._id !== 1) {
      sendRequest();
    }
  }, [sendRequest, vehicle._id]);

  const showFilterMenuHandler = () => {
    setShowFilterMenu(!showFilterMenu);
  };

  const showBulkMenuHandler = () => {
    setShowBulkMenu(!showBulkMenu)
  }

  let errorMessage = null
  if(tasksError){
    errorMessage = (
      <div className="task-form__label--invalid">
        Unable to load tasks. Please try again later.
      </div>
    );
  }

  const tasksMap = currentTasks.map((task, index) => {
    return (
      <Task
        key={task._id}
        number={index + 1 + (currentPage - 1) * PageSize}
        task={task}
        vehicle={vehicle}
        actionsMenuActiveId={actionsMenuActiveId}
        setActionsMenuActiveId={setActionsMenuActiveId}
      />
    );
  });

  useEffect(() => {
    if(!bulkActionLoading && !bulkActionError && bulkActionData === 'success'){
      setBulkActionClasses(
        'form__success-msg-fade--show'
      );
      setShowBulkMenu(false);
      setTimeout(() => {
        setBulkActionClasses('')
      }, 5000)
    }
  },[bulkActionLoading, bulkActionError, bulkActionData])
  

  return (
    <div className="task-list" id="taskList">
      <div className="task-bar u-margin-bottom-mediumsmall">
        <ul className="task-bar__list">
          <li className="col-1-of-3 task-bar__item">
            <div className="task-bar__link" onClick={showFilterMenuHandler}>
              <Filter />
              <p className="task-bar__text">Filter</p>
            </div>
          </li>
          <li className="col-1-of-3 task-bar__item">
            <a href="#taskModal" className="task-bar__link">
              <PlusSquare />
              <p className="task-bar__text">Add</p>
            </a>
          </li>
          <li className="col-1-of-3 task-bar__item">
            <div className="task-bar__link" onClick={showBulkMenuHandler}>
              <Layers />
              <p className="task-bar__text">Bulk</p>
            </div>
          </li>
        </ul>
      </div>
      {tasks.length === 0 && (
        <div className="task-list__placeholder">
          <p className="u-margin-bottom-small">Please start by adding tasks.</p>
          <a href="#taskModal" className="btn btn--primary">
            Add Tasks
          </a>
        </div>
      )}
      <div className={`form__success-msg-fade ${bulkActionClasses}`}>
          <p >
            Bulk action successful.
          </p>
        </div>
      {!tasksError ? (!tasksLoading ? tasksMap : <LoadingSpinner />) : errorMessage}
      <Pagination className="pagination-bar" currentPage={currentPage} totalCount={tasks.length} pageSize={PageSize} onPageChange={(page) => setCurrentPage(page)} />
      <FilterMenu
        className={showFilterMenu ? "popup-menu--show" : ""}
        setShowFilterMenu={setShowFilterMenu}
        onSortClick={sortClickHandler}
        filterMenuActiveId={filterMenuActiveId}
      />
      <BulkMenu className={showBulkMenu ? "popup-menu--show" : ""} setShowBulkMenu={setShowBulkMenu} onBulkActionsClick={bulkActionsClickHandler}/>
    </div>
  );
};

export default TaskList;