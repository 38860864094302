import { ArrowLeft, ArrowRight } from "react-feather";
import { usePagination, DOTS } from "../../hooks/usePaginations";

const Pagination = (props) => {
    const {onPageChange, totalCount, siblingCount = 1, currentPage, pageSize} = props

    const paginationRange = usePagination({currentPage, totalCount, siblingCount, pageSize})

    if(currentPage === 0 || paginationRange.length < 2){
        return null;
    }

    const onNext = () => {
        if(currentPage < paginationRange.length){
            onPageChange(currentPage + 1)
        } else {
            onPageChange(1)
        }
            
    }

    const onPrevious = () => {
        if(currentPage > 1){
            onPageChange(currentPage - 1)
        } else {
            onPageChange(paginationRange.length)
        }
    }

    return(
        <ul className="pagination-container">
            <li className="pagination-item" onClick={onPrevious} key='leftArrow'>
                <ArrowLeft />
            </li>
            {paginationRange.map((pageNumber, index) => {
                if(pageNumber === DOTS){
                    return <li className="pagination-item__dots" key={index}>&#8230;</li>
                }
                return(
                    <li key={index} className={`pagination-item ${pageNumber === currentPage && 'pagination-item--active' }`} onClick={() => onPageChange(pageNumber)}>
                        {pageNumber}
                    </li>
                )
            })}
            <li className="pagination-item" onClick={onNext} key='rightArrow'>
                <ArrowRight />
            </li>
        </ul>
    )
}

export default Pagination