import { useEffect, useRef, useState } from "react";
import { ChevronsDown, CheckSquare, FileText, Package, Youtube, Sliders, Plus, Loader, AlertTriangle } from "react-feather";
import { CSSTransition } from 'react-transition-group'

import useFetch from "../../hooks/useFetch";

import { addTaskToSchedule } from "../util/libAPI";
import getUsageUnit from "../util/getUsageUnit";

import Done from "./taskActions/Done";
import History from "./taskActions/History";
import HowTo from "./taskActions/HowTo";
import Parts from "./taskActions/Parts";
import Edit from "./taskActions/Edit";

const Task = (props) => {
  let task = {...props.task}
  const vehicle = props.vehicle
  const nodeRef = useRef(null)

  const {loading: taskLoading, error:taskError, sendRequest} = useFetch(addTaskToSchedule)

  const initialState = {
    show: false,
    classes: 'actions-menu__icon'
  }

  const [showActionsMenu, setShowActionsMenu] = useState({
    show: false,
    classes: 'task__action task__action'
  })

  const [taskIsActive, setTaskIsActive] = useState({
    active: false,
    classes: 'task'
  })

  const [actionsMenuClasses, setActionsMenuClasses] = useState('actions-menu--active')
  const [showDoneAction, setShowDoneAction] = useState(initialState)
  const [showHistoryAction, setShowHistoryAction] = useState(initialState)
  const [showPartsAction, setShowPartsAction] = useState(initialState)
  const [showHowToAction, setShowHowToAction] = useState(initialState)
  const [showEditAction, setShowEditAction] = useState(initialState)

  const unit = getUsageUnit(props.vehicle.usageUnit)
  task.interval = parseInt(unit === 'mls' ? task.interval * 30 : unit === 'km' ? task.interval * 50 : task.interval);

  const hideAllActionsHandler = () => {
    setShowDoneAction((prevState) => ({
        ...prevState,
        show: false,
        classes: 'actions-menu__icon'
      })
    )
    setShowHistoryAction((prevState) => ({
        ...prevState,
        show: false,
        classes: 'actions-menu__icon'
      })
    )
    setShowPartsAction((prevState) => ({
        ...prevState,
        show: false,
        classes: 'actions-menu__icon'
      })
    )
    setShowHowToAction((prevState) => ({
        ...prevState,
        show: false,
        classes: 'actions-menu__icon'
      })
    )
    setShowEditAction((prevState) => ({
        ...prevState,
        show: false,
        classes: 'actions-menu__icon'
      })
    )
  }

  const showActionsMenuHandler = () => {
    hideAllActionsHandler()
    props.setActionsMenuActiveId(props.number)
    setShowActionsMenu((prevState) => {
      return {
        ...prevState,
        show: !showActionsMenu.show,
        classes: showActionsMenu.classes.includes('--active') ? 'task__action' : 'task__action task__action--active'
      }
    })

    setTaskIsActive((prevState) => {
      return {
        ...prevState,
        active: !taskIsActive.active,
        classes: taskIsActive.classes.includes('--active') ? 'task' : 'task task--active'
      }
    })

    setActionsMenuClasses((prevState) => prevState.includes('--active') ? 'actions-menu' : 'actions-menu actions-menu--active')
  }

  const addTaskToScheduleHandler = () => {
    sendRequest(props.task)
  }

  useEffect(() => {
    if(props.actionsMenuActiveId !== props.number){
      setShowActionsMenu((prevState) => {
        return {
          ...prevState,
          show: false,
          classes: 'task__action'
        }
      })

      setTaskIsActive((prevState) => {
        return {
          ...prevState,
          active: false,
          classes: 'task'
        }
      })
      setActionsMenuClasses('actions-menu')
    }
  }, [props.actionsMenuActiveId, props.number])

  const actionsMenuClickHandler = (action) => {
    
    hideAllActionsHandler()
    
    const actions = {
      done: () => setShowDoneAction((prevState) => {
        return {
          ...prevState,
          show:!showDoneAction.show,
          classes: showDoneAction.classes.includes('--active') ? 'actions-menu__icon' : 'actions-menu__icon actions-menu__icon--active'
        }
      }),
      history: () => setShowHistoryAction((prevState) => {
        return {
          ...prevState,
          show:!showHistoryAction.show,
          classes: showHistoryAction.classes.includes('--active') ? 'actions-menu__icon' : 'actions-menu__icon actions-menu__icon--active'
        }
      }),
      parts: () => setShowPartsAction((prevState) => {
        return {
          ...prevState,
          show:!showPartsAction.show,
          classes: showPartsAction.classes.includes('--active') ? 'actions-menu__icon' : 'actions-menu__icon actions-menu__icon--active'
        }
      }),
      howto: () => setShowHowToAction((prevState) => {
        return {
          ...prevState,
          show:!showHowToAction.show,
          classes: showHowToAction.classes.includes('--active') ? 'actions-menu__icon' : 'actions-menu__icon actions-menu__icon--active'
        }
      }),
      edit: () => setShowEditAction((prevState) => {
        return {
          ...prevState,
          show:!showEditAction.show,
          classes: showEditAction.classes.includes('--active') ? 'actions-menu__icon' : 'actions-menu__icon actions-menu__icon--active'
        }
      })
    }
    actions[action]()
  }

  const doneAt = task.lastCompleted?.length > 0 ? parseInt(task.lastCompleted.at(-1).hours) : 0
  const buffer =  vehicle?.usageUnit === 'hours' ? 10 : 100
  let dueIn = (doneAt + task.interval - vehicle?.ODO)

  let taskNumberClasses = "";
  if (vehicle?.ODO - doneAt < task.interval || props.tasksModal) {
    taskNumberClasses = "task__number--notDue";
    dueIn = (doneAt + task.interval - vehicle?.ODO);
  } else if (
    vehicle?.ODO - doneAt >= task.interval &&
    vehicle?.ODO - doneAt - task.interval < buffer
  ) {
    taskNumberClasses = "task__number--due";
    dueIn = "Now";
  } else {
    dueIn = "Past Due";
    taskNumberClasses = "task__number--past-due";
  }

  return (
    <div className={taskIsActive.classes}>
      <div className="row task-row">
        <div className="col-1-of-3">
          <div className={`task__number ${taskNumberClasses}`}>{props.number}</div>
        </div>
        <div className="col-1-of-3">
          <div className="task__description">
            <div className="task__title">{task.description}</div>
            <div className="row">
              <div className="task__details">
                <div className="col-1-of-2">Interval: {`${task.interval} ${unit}`}</div>
                <div className="col-1-of-2">Due in: {!props.tasksModal ? dueIn > 0 ? `${dueIn} ${unit}` : `${dueIn}` : '-' }</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-1-of-3">
          {!props.tasksModal && <div className={showActionsMenu.classes} onClick={showActionsMenuHandler}>
            <ChevronsDown className="task__action-item" />
          </div>}
          {props.tasksModal && <div className={showActionsMenu.classes} onClick={!taskLoading ? addTaskToScheduleHandler : undefined}>
            {taskError ? <AlertTriangle className="task__action-item" /> : 
              taskLoading ? <Loader className="task__action-item" /> : <Plus className="task__action-item" />
            }
          </div>}
        </div>
      </div>
      <div className="row">
         <CSSTransition in={showActionsMenu.show} nodeRef={nodeRef} timeout={300} classNames="actions-menu" unmountOnExit>
         <div className={actionsMenuClasses} ref={nodeRef}>
         <ul className="actions-menu__list">
            <li className="actions-menu__item">
              <label className="actions-menu__label" onClick={() => actionsMenuClickHandler('done')}>
                <span className={showDoneAction.classes}><CheckSquare className="actions-menu__symbol"/></span>
                <span className="actions-menu__text">Done</span>
              </label>
            </li>
            <li className="actions-menu__item">
              <label className="actions-menu__label" onClick={() => actionsMenuClickHandler('history')}>
                <span className={showHistoryAction.classes}><FileText className="actions-menu__symbol" /></span>
                <span className="actions-menu__text">History</span>
              </label>
            </li>
            <li className="actions-menu__item">
              <label className="actions-menu__label" onClick={() => actionsMenuClickHandler('parts')}>
                <span className={showPartsAction.classes}><Package className="actions-menu__symbol" /></span>
                <span className="actions-menu__text">Parts</span>
              </label>
            </li>
            <li className="actions-menu__item">
              <label className="actions-menu__label" onClick={() => actionsMenuClickHandler('howto')}>
                <span className={showHowToAction.classes}><Youtube className="actions-menu__symbol" /></span>
                <span className="actions-menu__text">HowTo</span>
              </label>
            </li>
            <li className="actions-menu__item">
              <label className="actions-menu__label" onClick={() => actionsMenuClickHandler('edit')}>
                <span className={showEditAction.classes}><Sliders className="actions-menu__symbol" /></span>
                <span className="actions-menu__text">Edit</span>
              </label>
            </li>
          </ul>
          <div className="actions-container">
            {showDoneAction.show && <div className="action"><Done task={task}/></div>}
            {showHistoryAction.show && <div className="action"><History task={task} usageUnit={unit}/></div>}
            {showPartsAction.show && <div className="action"><Parts task={task}/></div>}
            {showHowToAction.show && <div className="action"><HowTo task={task} /></div>}
            {showEditAction.show && <div className="action"><Edit task={task} vehicle={vehicle}/></div>}
          </div>
        </div></CSSTransition>
      </div>
    </div>
  );
};

export default Task