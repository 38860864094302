import { useSelector } from "react-redux";
import { Settings, PlusCircle, MinusCircle } from "react-feather";
import { useNavigate } from "react-router-dom";

import { deleteVehicle } from "../../util/libAPI";
import { getActiveVehicleId } from "../../redux/slices/vehicleSlice";
import useFetch from "../../../hooks/useFetch";

import UpdateCard from "./UpdateCard";
import { useEffect, useState } from "react";

const VehicleActions = () => {
  const navigate = useNavigate()
  const activeVehicleId = useSelector(getActiveVehicleId);
  const [showCard, setShowCard] = useState(false)
  const {loading: deleteVehicleLoading, error: deleteVehicleError, sendRequest: deleteVehicleRequest} = useFetch(deleteVehicle)

  useEffect(() => {
    if(activeVehicleId !== 1 && activeVehicleId !== 0){
        setShowCard(true)
    } else {
        setShowCard(false)
    }
  }, [activeVehicleId])

  const deleteVehicleHandler = () => {
    deleteVehicleRequest();
  };

  const addVehicleHandler = () => {
    navigate("#editModal", {state: {type: "addVehicleModal"}})
  }

  const editVehicleHandler = () => {
    navigate("#editModal", {state: {type: "editModal"}})
  }

  return (
    <section className="vehicle-actions" id="vehicleActions">
      <div className="u-center-text u-margin-bottom-mediumsmall">
        <h2 className="heading-secondary" id="how_it_woks">
          Vehicle Actions
        </h2>
      </div>
      
      <div className="actions">
      <div className="actions__bg">
      <div className="actions__bg--img">
      <video className="bg-video__content" autoPlay muted loop playsInline>
            <source src="img/tree.mp4" type="video/mp4" />
            <source src="img/tree.webm" type="video/webm" />
            Your browser is not supported!
          </video>
      </div>
      </div>
      
        <div className="row actions__row">
          <div className="col-1-of-4 actions__column">
            {showCard && <UpdateCard />}
          </div>

          <div className="col-1-of-4 actions__column">
            {showCard && <div className="card">
              <div className="card__side card__side--front">
                <div className="card__picture">
                  <Settings className="card__picture-icon" />
                </div>
                <h4 className="card__heading">
                  <span className="card__heading-span card__heading-span--2">
                    Edit
                  </span>
                </h4>
                
              </div>
              <div className="card__side card__side--back card__side--back-2">
                <div className="card__cta card__cta-2">
                  <div className="card__form-box card__form-box-2">
                    <div className="card__cta">
                      <div className="card__form-box">
                        <p className="card__back-text">Edit this vehicle?</p>
                        <a href="#editModal" className="btn--action btn--white" onClick={editVehicleHandler}>
                          Edit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </div>

          <div className="col-1-of-4 actions__column">
            <div className="card">
              <div className="card__side card__side--front">
                <div className="card__picture">
                  <PlusCircle className="card__picture-icon" />
                </div>
                <h4 className="card__heading">
                  <span className="card__heading-span card__heading-span--3">
                    Add
                  </span>
                </h4>
                
              </div>
              <div className="card__side card__side--back card__side--back-3">
                <div className="card__cta">
                  <div className="card__form-box">
                    <p className="card__back-text">
                      Add another vehicle to your garage?
                    </p>
                    <a href="#editModal" className="btn--action btn--white" onClick={addVehicleHandler} id="addVehicleLink">
                      Add Vehicle
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-1-of-4">
            {showCard && <div className="card">
              <div className="card__side card__side--front">
                <div className="card__picture">
                  <MinusCircle className="card__picture-icon" />
                </div>
                <h4 className="card__heading">
                  <span className="card__heading-span card__heading-span--4">
                    Delete
                  </span>
                </h4>
                
              </div>
              <div className="card__side card__side--back card__side--back-4">
                <div className="card__cta">
                  <div className="card__form-box">
                    <p className="card__back-text">
                      Permanently delete this vehicle?
                    </p>
                    <button
                      type="submit"
                      className="btn--action btn--white"
                      onClick={deleteVehicleHandler}
                      disabled={deleteVehicleLoading}
                    >
                      {deleteVehicleError ? <p className="form__label--invalid">Error. Try again later.</p> : 
                        deleteVehicleLoading ? "Loading..." : "Delete Now!"}
                    </button>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VehicleActions;