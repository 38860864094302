import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";
import { getAllTasksByVehicleId } from "../redux/slices/taskSlice";
import {
  getActiveVehicleId,
  getActiveVehicle,
} from "../redux/slices/vehicleSlice";
import Task from "./Task";
import { XSquare } from "react-feather";
import {
  getCatalogTasks,
  getCustomTasks,
  createCustomTask,
} from "../util/libAPI";
import EditTaskForm from "../forms/EditTaskForm";
import Pagination from "../util/Pagination";
import LoadingSpinner from "../util/LoadingSpinner";

const PageSize = 10;

const AddTasksToManager = () => {
  const activeVehicleId = useSelector(getActiveVehicleId);
  const vehicle = useSelector(getActiveVehicle) || {};
  const [currentPage, setCurrentPage] = useState(1);
  const [catalogTasks, setCatalogTasks] = useState([]);
  const [customTasks, setCustomTasks] = useState([]);
  const [vehicleTasks, setVehicleTasks] = useState([]);
  const [displayTasks, setDisplayTasks] = useState([]);
  const [showCatalogTasks, setShowCatalogTasks] = useState(true);
  const [showNewCustomTaskForm, setShowNewCustomTaskForm] = useState(false);
  const reduxTasks = useSelector((state) =>
    getAllTasksByVehicleId(state, activeVehicleId)
  );

  const {
    loading: catalogLoading,
    error: catalogError,
    data: catalogData,
    sendRequest: fetchCatalogTasks,
  } = useFetch(getCatalogTasks);

  const {
    loading: customLoading,
    error: customError,
    data: customData,
    sendRequest: fetchCustomTasks,
  } = useFetch(getCustomTasks);

  const {
    loading: createTaskLoading,
    error: createTaskError,
    data: createTaskData,
    sendRequest: fetchCreateTask,
  } = useFetch(createCustomTask);

  useEffect(() => {
    if (reduxTasks) {
      setVehicleTasks(reduxTasks.tasks);
    }
  }, [reduxTasks]);

  useEffect(() => {
    fetchCatalogTasks();
    fetchCustomTasks();
  }, [fetchCatalogTasks, fetchCustomTasks]);

  useEffect(() => {
    if (catalogData) {
      setCatalogTasks(catalogData);
    }
  }, [catalogData]);

  useEffect(() => {
    if (customData) {
      setCustomTasks(customData);
    }
  }, [customData]);

  useEffect(() => {
    if (createTaskData && !createTaskLoading && !createTaskError) {
      setCustomTasks((prevState) => {
        return [...prevState, createTaskData];
      });
    }
  }, [createTaskData, createTaskLoading, createTaskError]);

  useEffect(() => {
    if (catalogTasks && customTasks && vehicleTasks) {
      if (showCatalogTasks) {
        setDisplayTasks(
          catalogTasks.filter(
            ({ description: catalogTask }) =>
              !vehicleTasks.some(
                ({ description: vehicleTask }) => vehicleTask === catalogTask
              )
          )
        );
      }
      if (!showCatalogTasks) {
        setDisplayTasks(
          customTasks.filter(
            ({ description: catalogTask }) =>
              !vehicleTasks.some(
                ({ description: vehicleTask }) => vehicleTask === catalogTask
              )
          )
        );
      }
    }
  }, [catalogTasks, vehicleTasks, showCatalogTasks, customTasks]);

  const currentTasks = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return displayTasks.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, displayTasks]);

  const showTasksHandler = (event) => {
    if (event.target.dataset.catalog) {
      setShowCatalogTasks(true);
    }
    if (event.target.dataset.custom) {
      setShowCatalogTasks(false);
    }
    setCurrentPage(1);
  };

  const showNewCustomTaskFormHandler = () => {
    setShowNewCustomTaskForm(true);
  };

  const cancelHandler = () => {
    setShowNewCustomTaskForm(false);
  };

  const sendHandler = (task) => {
    const newTask = {
      ...task,
      customTask: true,
    };
    fetchCreateTask(newTask);
  };

  const btnDef = (
    <div className="form__group u-margin-bottom-small">
      <button type="submit" className="btn btn--primary">
        Create!
      </button>
      <button
        type="button"
        onClick={cancelHandler}
        className="btn--text btn--text-inline"
      >
        Cancel
      </button>
    </div>
  );

  const tasksToDispay = currentTasks.map((task, index) => {
    return (
      <Task
        key={index}
        number={index + 1 + (currentPage - 1) * PageSize}
        task={task}
        vehicle={vehicle}
        tasksModal={true}
        vehicleId={activeVehicleId}
      />
    );
  });

  return (
    <div className="tasksToDisplay">
      <div className="taskModal__close">
        <a href="#taskManager" className="taskModal__close-link">
          <XSquare className="taskModal__close-icon" />
        </a>
      </div>
      <div className="u-center-text u-margin-bottom-mediumsmall">
        <h2 className="heading-secondary" id="add_tasks">
          Add Tasks
        </h2>
      </div>
      <div className="toggle toggle--addTasks u-margin-bottom-mediumsmall">
        <div className="toggle__radio-group toggle__radio-group--addTasks">
          <input
            type="radio"
            id="toggle_radio"
            name="toggle_signin"
            className="toggle__radio-btn"
            checked={showCatalogTasks}
            onChange={() => {}}
          />
          <label
            htmlFor="toggle_radio"
            className="toggle__label toggle__label--addTasks"
            data-catalog={true}
            onClick={showTasksHandler}
          >
            <span className="toggle__custom-radio toggle__custom-radio--tasks" />
            Task Catalog
          </label>
        </div>
        <div className="toggle__radio-group toggle__radio-group--addTasks">
          <input
            type="radio"
            id="signin_radio"
            name="toggle_signin"
            className="toggle__radio-btn"
            checked={!showCatalogTasks}
            onChange={() => {}}
          />
          <label
            htmlFor="signin_radio"
            className="toggle__label toggle__label--addTasks"
            data-custom={true}
            onClick={showTasksHandler}
          >
            <span className="toggle__custom-radio toggle__custom-radio--tasks" />
            Custom Tasks
          </label>
        </div>
      </div>
      {!showCatalogTasks && (
        <div className="u-center-inline-content u-margin-bottom-medium">
          {!showNewCustomTaskForm && (
            <button
              type="button"
              className="btn btn--primary btn--primary-centered"
              onClick={showNewCustomTaskFormHandler}
            >
              New custom task
            </button>
          )}
          {showNewCustomTaskForm && (
            <EditTaskForm
              btnDef={btnDef}
              showLegend={true}
              taskHandler={{
                sendHandler,
                createTaskData,
                createTaskError,
                createTaskLoading,
              }}
            />
          )}
        </div>
      )}
      {(catalogError || customError) && (
        <p className="form__label--invalid">
          An error occured. Please try again later.
        </p>
      )}
      {catalogLoading || customLoading ? <LoadingSpinner /> : tasksToDispay}
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={displayTasks.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default AddTasksToManager;
