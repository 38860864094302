const dummyData = () => {
  return [
    {
      _id: 1,
      type: "Click to Add a vehicle",
      year: null,
      make: "Add",
      model: "A Vehicle",
      usageUnit: "hours",
      ODO: 0,
      img: "/img/ktm_1.jpg",
      lastUpdate: "",
    },
    {
      _id: 2,
      type: "Quad",
      year: 2017,
      make: "Yamaha",
      model: "Banshee 700 RR",
      usageUnit: "hours",
      ODO: 358,
      img: "/img/quad_pure.jpg",
      lastUpdate: "26/12/2022",
    },
    {
      _id: 3,
      type: "SxS",
      year: 2022,
      make: "CanAm",
      model: "X3 Turbo",
      usageUnit: "miles",
      ODO: 2596,
      img: "/img/sxs_pure.jpg",
      lastUpdate: "25/11/2022",
    },
  ];
};

export default dummyData