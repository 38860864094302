import { Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import ProtectedRoute from "./utils/ProtectedRoute";
import Welcome from "./pages/Welcome/Welcome";
import Garage from "./pages/Garage/Garage";
import About from "./pages/About/About";
import Activate from "./pages/Activate/Activate";
import Logout from "./pages/Logout/Logout";
import { parseString} from "./components/util/objectUtils";

import { login, getUser } from "./components/redux/slices/authSlice";
import Terms from "./pages/Legal/Terms";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";

function App() {
  const [cookies,] = useCookies(["user"]);
  const dispatch = useDispatch();
  const userRedux = useSelector(getUser);
  const location = useLocation()
  const navigate = useNavigate()
  const [, , removeCookie] = useCookies(['user'])

  useEffect(() => {
    const user = parseString(cookies.user);
    if (!user) {
      return;
    }
    if (!userRedux.isAuthenticated && location.pathname !== '/logout') {
      dispatch(login(user));
      navigate(location.pathname)
    }
    if(userRedux.isAuthenticated){
      setTimeout(() => {
        removeCookie('user')
        navigate("/logout")
      }, user.expiresIn)
    }
  }, [cookies, dispatch, userRedux.isAuthenticated, location.pathname, navigate, removeCookie]);

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/welcome" />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route
        path="/garage/:id"
        element={
          <ProtectedRoute>
            <Garage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/garage"
        element={
          <ProtectedRoute>
            <Garage />
          </ProtectedRoute>
        }
      />
      <Route path="/aboutus" element={<About />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/activate/:id" element={<Activate />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<Welcome />} />
    </Routes>
  );
}

export default App;