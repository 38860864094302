const Modal = (props) => {
    return (
        <div className={`modal ${props.className}`} id={props.modalId}>
            <div className={`modal__content ${props.className}__content`}>
                {props.children}
            </div>
        </div>
    )
}

export default Modal