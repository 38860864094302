import GetActiveVehicle from "../../util/GetActiveVehicle";

const Parts = (props) => {
  const activeVehicle = GetActiveVehicle();
  const vehicleString =
    activeVehicle?.make +
    "+" +
    activeVehicle?.model;
  const taskString = props.task.description.replace("Change ", "").replace(" ", "+");

  return (
    <div className="parts">
      <h3 className="heading-tertiary task-form__heading u-margin-bottom-small">
        Find parts for your {activeVehicle?.type} on:{" "}
      </h3>
      <div className="parts__logo-container">
        <a
          href={`https://www.amazon.com/s?k=${vehicleString}+${taskString}&tag=braapdoc0a-20`}
          target="_blank"
          rel="noreferrer"
          className="logo-link"
        >
          <img className="logo" src="/img/amazon.svg" alt="amazon logo" />
        </a>
        <a
          href={`https://www.ebay.com/sch/i.html?_nkw=${vehicleString}+${taskString}&campid=5338766719&customid=BraapDoc`}
          target="_blank"
          rel="noreferrer"
          className="logo-link"
        >
          <img className="logo" src="/img/ebay.svg" alt="ebay logo" />
        </a>
        <a
          href={`https://www.revzilla.com/search?_utf8=%E2%9C%93&query=${vehicleString}+${taskString}&irclickid=3CsU9e1-hxyNT8sSvXVIdUGvUkDS2O2Y00000w0&irgwc=1&utm_source=Impact&utm_medium=affiliate_&utm_campaign=3769165&utm_term=BraapDoc`}
          target="_blank"
          rel="noreferrer"
          className="logo-link"
        >
          <img className="logo" src="/img/revzilla.svg" alt="revzilla logo" />
        </a>
      </div>
    </div>
  );
};

export default Parts;