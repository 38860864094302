import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";

import useInputValidator from "../../../hooks/useInputValidator";

import { updateLastCompleted } from "../../util/libAPI";

import GetActiveVehicle from "../../util/GetActiveVehicle";

const Done = (props) => {
  const [vehicle, setVehicle] = useState({});
  const activeVehicle = GetActiveVehicle();

  const {
    loading: taskLoading,
    error: taskError,
    data: taskData,
    sendRequest,
  } = useFetch(updateLastCompleted);

  useEffect(() => {
    if (activeVehicle) {
      setVehicle(activeVehicle);
    }
  }, [activeVehicle]);

  const { inputHasError, inputClasses, blurHandler } = useInputValidator({
    validateData: (value) => parseInt(value.trim()) >= 0,
  });

  const odoUpdateHandler = (e) => {
    blurHandler(e);

    setVehicle((prevState) => {
      return {
        ...prevState,
        ODO: e.target.value,
      };
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (inputHasError) {
      return;
    }

    sendRequest({ task: props.task, hours: vehicle.ODO });
  };

  let successMessage = "";

  if (taskData && !taskError && !taskLoading) {
    successMessage = (
      <div>
        <p className="form__success-msg u-margin-bottom-small">
          Changes saved successfully.
        </p>
      </div>
    );
  }

  let errorMessage = "";
  if (taskError) {
    errorMessage = (
      <div className="task-form__label--invalid">
        An error occured. Please try again later.
      </div>
    );
  }

  return (
    <form className="form" onSubmit={submitHandler}>
      <div className="form__group u-margin-bottom-small">
        <label htmlFor="doneInput" className="form__label task-form__label">
          Task completed at:
        </label>
        <input
          type="number"
          id="doneInput"
          className={`form__input task-form__input ${inputClasses}`}
          min={0}
          step={1}
          value={parseInt(vehicle.ODO) || 0}
          onChange={odoUpdateHandler}
        />
      </div>
      {inputHasError && (
        <div className="task-form__label--invalid">
          Value cannot be empty or negative.
        </div>
      )}
      {!taskError ? (
        !successMessage ? (
          <button type="submit" className="btn btn--primary">
            {!taskLoading ? "Update!" : "Loading..."}
          </button>
        ) : (
          successMessage
        )
      ) : (
        { errorMessage }
      )}
    </form>
  );
};

export default Done;