import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ArrowRight } from "react-feather";
import MainMenu from "../../components/menu/MainMenu";
import SignupLoginForm from "../../components/forms/SignupLoginForm";
import GoogleLogin from "../../components/1-click-logins/GoogleLogin";
import FacebookLogin from "../../components/1-click-logins/FacebookLogin";

const Welcome = () => {
  const [socialLoginError, setSocialLoginError] = useState(false)
  const [showSignup, setShowSignup] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#signup") {
      const signup = document.getElementById("signup");
      signup.scrollIntoView();
    }
  }, [location]);

  const showSignupHandler = (event) => {
    if (event.target.dataset.signup) {
      setShowSignup(true);
    }
    if (event.target.dataset.signin) {
      setShowSignup(false);
    }
  };

  return (
    <div className="welcome">
      <MainMenu />
      <section className="opener">
        <div className="opener__bg-image"></div>
        <div className="opener__logo-box">
          <img src="img/BRAAPDoc_HD2.png" alt="Logo" className="opener__logo" />
        </div>
        <div className="opener__text-box">
          <h1 className="heading-primary">
            <span className="heading-primary--main">The Off-Road Garage</span>
            <span className="heading-primary--sub">
              Schedule and track your service tasks.
            </span>
          </h1>
          <a href="#discover_more" className="btn btn--white btn--animated">
            Discover More
          </a>
        </div>
      </section>
      <section className="app">
        <div className="u-center-text">
          <h2 className="heading-secondary" id="discover_more">
            One Garage to Fit them All
          </h2>
        </div>
        <div className="app__content">
          <div className="app__background"></div>
          <div className="row">
            <div className="col-1-of-2">
              <div className="photo-container">
                <div className="photo-container__photo photo-container__photo--1"></div>
              </div>
            </div>
            <div className="col-1-of-2">
              <div className="app__text-box">
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Longevity for your off-road toy!
                </h3>
                <p className="paragraph">
                  Regular maintenance is a key part in keeping your off-road
                  vehicle ready to hit the trail, track, or anything in between.
                </p>
                <a href="#signup" className="btn--text">
                  Sign Up <ArrowRight className="btn--text__icon" />
                </a>
              </div>
            </div>
          </div>
          <div className="row app__flexbox">
            <div className="col-1-of-2">
              <div className="app__text-box">
                <h3 className="heading-tertiary u-margin-bottom-small">
                  You're in control!
                </h3>
                <p className="paragraph">
                  Take the commanders seat and enjoy full control over your
                  motor pool. Whatever you ride. Wherever you ride.
                </p>
                <a href="#how_it_woks" className="btn--text">
                  How it works <ArrowRight className="btn--text__icon" />
                </a>
              </div>
            </div>
            <div className="col-1-of-2">
              <div className="photo-container">
                <div className="photo-container__photo photo-container__photo--2"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-1-of-2">
              <div className="photo-container">
                <div className="photo-container__photo photo-container__photo--3"></div>
              </div>
            </div>
            <div className="col-1-of-2">
              <div className="app__text-box">
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Ride more, smile more!
                </h3>
                <p className="paragraph">
                  BraapDoc was developed to make sure our dirt-toys are in the
                  best condition possible. So we can ride more and have fun.
                </p>
                <a href="#user_reviews" className="btn--text">
                  User Reviews <ArrowRight className="btn--text__icon" />
                </a>
              </div>
            </div>
          </div>
          <div className="row app__flexbox">
            <div className="col-1-of-2">
              <div className="app__text-box">
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Never miss a ride again!
                </h3>
                <p className="paragraph">
                  Schedule and track service tasks easily and enjoy the peace of
                  mind that your machine is in immaculate condition.
                </p>
                <a href="#signup" className="btn--text">
                  Sign Up Now <ArrowRight className="btn--text__icon" />
                </a>
              </div>
            </div>
            <div className="col-1-of-2">
              <div className="photo-container">
                <div className="photo-container__photo photo-container__photo--4"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="screen-sizes">
        <div className="bg-video">
          <video className="bg-video__content" autoPlay muted loop playsInline>
            <source src="img/river.mp4" type="video/mp4" />
            <source src="img/river.webm" type="video/webm" />
            Your browser is not supported!
          </video>
        </div>
        <div className="u-center-text u-margin-bottom-small">
          <h2 className="heading-secondary" id="how_it_woks">
            Designed for all devices
          </h2>
        </div>
        <div className="row">
          <div className="col-1-of-2">
            <div className="screen__text-box">
              <h3 className="heading-tertiary u-margin-bottom-small">
                No Downloads!
              </h3>
              <p className="paragraph">
                BraapDoc is a state-of-the-art web application. It runs directly
                from your browser and offers convenient 1-click login and signup
                options.
              </p>
              <h3 className="heading-tertiary u-margin-bottom-small">
                No Installation!
              </h3>
              <p className="paragraph">
                Because BraapDoc is a web application, it is available to you on
                any of your devices. Laptop, PC, Phone, or Tablet, all hassle
                free.
              </p>
            </div>
          </div>
          <div className="col-1-of-2">
            <div className="picture-box">
              <img
                src="img/dirt_bike_monitor.png"
                alt="Dirt bike monitor mockup"
                className="picture-box__photo picture-box__photo--1"
              />
              <img
                src="img/iPhone_sxs.png"
                alt="SxS iphone mockup"
                className="picture-box__photo picture-box__photo--2"
              />
              <img
                src="img/quad_macbook.png"
                alt="Quad Macbook mockup"
                className="picture-box__photo picture-box__photo--3"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="reviews">
        <div className="u-center-text u-margin-bottom-small">
          <h2 className="heading-secondary" id="user_reviews">
            User Reviews
          </h2>
        </div>
        <div className="reviews__container">
          <div className="reviews__background"></div>
          <div className="row">
            <div className="user-reviews">
              <figure className="user-reviews__shape">
                <img
                  src="img/review_person.jpg"
                  className="user-reviews__image"
                  alt="Avatar"
                />
                <figcaption className="user-reviews__name">
                  Blake Richards
                </figcaption>
              </figure>
              <div className="user-reviews__text">
                <h3 className="heading-tertiary u-margin-bottom-small user-reviews__heading">
                  I would suggest that any serious rider give it a try.
                </h3>
                <p>
                  I am constantly performing service and repair on several
                  motorcycles, but sometimes forget to make maintenance entries
                  into owner’s manuals, or just plain forget to perform some of
                  the uncommon tasks that are important. Not only do I sometimes
                  forget which bike I performed specific maintenance tasks on,
                  but sometimes I waste time by performing tasks twice on the
                  same bike! The BraapDoc app is useful to use as a reminder of
                  the common, and not so common, tasks. The app also retains the
                  maintenance record, helping to avoid performing the task
                  twice.
                </p>
              </div>
            </div>
            <div className="user-reviews">
              <figure className="user-reviews__shape">
                <img
                  src="img/Tarantula.jpg"
                  className="user-reviews__image"
                  alt="Avatar"
                />
                <figcaption className="user-reviews__name">
                  Tom Gruner
                </figcaption>
              </figure>

              <div className="user-reviews__text">
                <h3 className="heading-tertiary u-margin-bottom-small user-reviews__heading">
                  Great app. fast, free, and works well.
                </h3>
                <p>
                  Regular and timely maintenance is the key difference between a
                  long lasting machine or an early breakdown. BraapDoc simply
                  helps me to stay informed on my machines' maintenance
                  schedule. The app is fast, free, and performs well on my
                  iPhone and computer. I highly recommend BraapDoc to anyone who
                  has a motosport/powersport related hobby.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-signup">
        <div className="u-center-text">
          <h2 className="heading-secondary" id="signup">
            Sign Up Now
          </h2>
        </div>
        <div className="row">
          <div className="signup">
            <div className="signup__container">
              <div className="signup__social-buttons">
                <FacebookLogin onError={setSocialLoginError}/>
                <GoogleLogin onError={setSocialLoginError}/>
                {socialLoginError && <div>
                  <p className="from_error">
                    An error occured. Please try again later.
                  </p>
                </div>}
              </div>
              <div className="divider"></div>
              <div className="signup__form-container">
                <div className="toggle">
                  <div className="toggle__radio-group">
                    <input
                      type="radio"
                      id="toggle_radio"
                      name="toggle_signin"
                      className="toggle__radio-btn"
                      checked={showSignup}
                      onChange={() => {}}
                    />
                    <label
                      htmlFor="toggle_radio"
                      className="toggle__label"
                      data-signup={true}
                      onClick={showSignupHandler}
                    >
                      <span className="toggle__custom-radio" />
                      Register
                    </label>
                  </div>
                  <div className="toggle__radio-group">
                    <input
                      type="radio"
                      id="signin_radio"
                      name="toggle_signin"
                      className="toggle__radio-btn"
                      checked={!showSignup}
                      onChange={() => {}}
                    />
                    <label
                      htmlFor="signin_radio"
                      className="toggle__label"
                      data-signin={true}
                      onClick={showSignupHandler}
                    >
                      <span className="toggle__custom-radio" />
                      Login
                    </label>
                  </div>
                </div>
                <SignupLoginForm signup={showSignup} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="footer__logo-container">
          <img src="img/BRAAPDoc_HD2.png" alt="Logo" className="footer__logo" />
        </div>
        <div className="row footer__text-container">
          <div className="col-1-of-2">
            <div className="copyright__container">
              <p className="footer__copyright">Copyright &copy; 2022 &nbsp;</p>
              <p className="footer__copyright">All Rights Reserved</p>
            </div>
          </div>
          <div className="col-1-of-2">
            <div className="footer__links">
              <ul className="footer__link-list">
                <li className="footer__link-item">
                  <a className="footer__link" href="/aboutus">
                    About Us
                  </a>
                </li>
                <li className="footer__link-item">
                  <a className="footer__link" href="/terms">
                    Terms of Use
                  </a>
                </li>
                <li className="footer__link-item">
                  <a className="footer__link" href="/privacypolicy">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/* <div className="row">
                    <div className="col-1-of-2">1-of-2</div>
                    <div className="col-1-of-2">1-of-2</div>
                </div>
                <div className="row">
                    <div className="col-1-of-3">1-of-3</div>
                    <div className="col-1-of-3">1-of-3</div>
                    <div className="col-1-of-3">1-of-3</div>
                </div>
                <div className="row">
                    <div className="col-1-of-3">1-of-3</div>
                    <div className="col-2-of-3">2-of-3</div>
                </div>
                <div className="row">
                    <div className="col-1-of-4">1-of-4</div>
                    <div className="col-1-of-4">1-of-4</div>
                    <div className="col-1-of-4">1-of-4</div>
                    <div className="col-1-of-4">1-of-4</div>
                </div>
                <div className="row">
                    <div className="col-1-of-4">1-of-4</div>
                    <div className="col-1-of-4">1-of-4</div>
                    <div className="col-2-of-4">2-of-4</div>
                </div>
                <div className="row">
                    <div className="col-1-of-4">1-of-4</div>
                    <div className="col-3-of-4">3-of-4</div>
                </div> */}
    </div>
  );
};

export default Welcome;
