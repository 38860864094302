import { useSelector } from "react-redux"

import { getActiveVehicleId, getVehicleById } from "../redux/slices/vehicleSlice"

const GetActiveVehicle = () => {
    const activeVehicleId = useSelector(getActiveVehicleId)
    const activeVehicle = useSelector((state) => getVehicleById(state, activeVehicleId))
    return activeVehicle
}

export default GetActiveVehicle