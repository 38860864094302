import LoadingSpinner from "../../components/util/LoadingSpinner"

const Loading = () => {
    return (
        <div className="loading">
            <div className="loading__spinner">
                <LoadingSpinner />
            </div>
        </div>
    )
}

export default Loading