const getUsageUnit = (usageUnit) => {
    const unitShort = {
        hours: 'hrs',
        miles: 'mls',
        kilometers: 'km',
        default: 'hrs'
      }
    
      return unitShort.hasOwnProperty(usageUnit) ? unitShort[usageUnit] : unitShort["default"]
}

export default getUsageUnit