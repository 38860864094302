
const FilterMenu = (props) => {

    const filterMenuOptions = [
        'Due soonest',
        'Done last',
        'A - Z',
        'Z - A'
    ]

    const filterMenuItems = filterMenuOptions.map((item, index) => {
        return (
            <li key={index} className={`popup-menu__list-item ${props.filterMenuActiveId === index ? 'popup-menu__list-item--active' : ''}`}
                onClick={props.onSortClick.bind(null, {criteria: item.toLowerCase(), id: index})}>{item}</li>
        )
    })

  return (
    <div className={`popup-menu popup-menu--filter ${props.className}`} id="popupMenu">
      <div className="popup-menu__bg" onClick={() => props.setShowFilterMenu()}></div>
      <div className="popup-menu__content">
        <ul className="popup-menu__list">
          <li className="popup-menu__list-item">Sort</li>
          {filterMenuItems}
        </ul>
      </div>
    </div>
  );
};

export default FilterMenu