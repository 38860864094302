const History = (props) => {

    const historyItems = props.task.lastCompleted.map((element, index) => {
        return (
            <li key={index} className="history__list-item">{element.date + ' at ' + element.hours + ' ' + props.usageUnit}</li>
        )
    })

    return (
        <div className="history">
            <h3 className="heading-tertiary task-form__heading u-margin-bottom-small">Task History:</h3>
            <ul className="history__list">
                {historyItems}
            </ul>
        </div>
    )
}

export default History