import { useState } from "react";

import useInputValidator from "../../hooks/useInputValidator";

const EditTaskForm = (props) => {
  const [task, setTask] = useState(props.task || {description: '', interval: 0});
  const {sendHandler, taskData, taskError, taskLoading} = props.taskHandler;
  
  const {
    inputHasError: descriptionHasError,
    inputClasses: descriptionInputClasses,
    blurHandler: descriptionBlurHandler,
  } = useInputValidator({
    validateData: (value) => value.trim() !== "",
  });

  const {
    inputHasError: intervalHasError,
    inputClasses: intervalInputClasses,
    blurHandler: intervalBlurHandler,
  } = useInputValidator({
    validateData: (value) => parseInt(value.trim()) >= 0,
  });

  const submitHandler = (e) => {
    e.preventDefault();

    const formIsValid = !descriptionHasError && !intervalHasError;

    if (!formIsValid) {
      return;
    }
    sendHandler(task)
  };

  const taskChangeHandler = (e) => {
    if (e.target.id === "descriptionInput") {
      descriptionBlurHandler(e);
      setTask((prevState) => {
        return {
          ...prevState,
          description: e.target.value,
        };
      });
    } else if (e.target.id === "intervalInput") {
      intervalBlurHandler(e);
      setTask((prevState) => {
        return {
          ...prevState,
          interval: parseInt(e.target.value),
        };
      });
    }
  };

  let successMessage = "";

  if (taskData && !taskError && !taskLoading) {
    successMessage = (
      <div>
        <p className="form__success-msg u-margin-bottom-small">
          Changes saved successfully.
        </p>
      </div>
    );
  }

  let errorMessage = "";
  if (taskError) {
    errorMessage = (
      <div className="task-form__label--invalid">
        An error occured. Please try again later.
      </div>
    );
  }

  return (
    <form className="form" onSubmit={submitHandler}>
      <fieldset className={`form__fieldset ${props.fieldsetClasses}`}>
        {props.showLegend && <legend className="form__legend">Create Custom Task</legend>}
      <div className="form__group u-margin-bottom-small">
        <label htmlFor="doneInput" className="form__label task-form__label">
          Task Description:
        </label>
        <input
          type="text"
          id="descriptionInput"
          className={`form__input ${descriptionInputClasses}`}
          value={task.description}
          onChange={taskChangeHandler}
        />
      </div>
      <div className="form__group u-margin-bottom-small">
        <label htmlFor="doneInput" className="form__label task-form__label">
          Task Interval:
        </label>
        <input
          type="number"
          id="intervalInput"
          className={`form__input ${intervalInputClasses}`}
          min={0}
          step={1}
          value={parseInt(task.interval) || 0}
          onChange={taskChangeHandler}
        />
      </div>
      {(descriptionHasError || intervalHasError) && (
        <div className="form__group">
          <p className="form__label--invalid">
            Please fill out the required fields.
          </p>
        </div>
      )}
      {!taskError ? (
        !successMessage ? (
          props.btnDef
        ) : (
          successMessage
        )
      ) : (
        errorMessage
      )}
      </fieldset>
    </form>
  );
};

export default EditTaskForm;