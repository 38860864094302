import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {useCookies} from 'react-cookie'
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import { googleLogout } from '@react-oauth/google';

import useFetch from '../../hooks/useFetch';

import { logout } from '../../components/redux/slices/authSlice'
import { logoutAll } from '../../components/util/libAPI';

const Logout = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [, , removeCookie] = useCookies(['user'])
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
    const {sendRequest: sendLogoutRequest} = useFetch(logoutAll)
    
    
    useEffect(() => {
        const domain = process.env.REACT_APP_DOMAIN;
        (async () => {
            googleLogout()

            FacebookLoginClient.clear();
            await FacebookLoginClient.loadSdk('en_US');
            FacebookLoginClient.init({ appId, version: 'v9.0' });
            setTimeout(() => {
                FacebookLoginClient.getLoginStatus((res) => {
                    if(res.status === "connected"){
                        FacebookLoginClient.logout((res) => {console.log(res)})
                      }
                })
            }, 1000)
        })();

        sendLogoutRequest()
        removeCookie('user', { path: '/', domain })
        dispatch(logout())
        setTimeout(() => {
            navigate('/')
        }, 3000);
    }, [removeCookie, dispatch, navigate, clientId, appId, sendLogoutRequest])

    return (
        <div>
            Logout successful. Redirecting in 3 seconds...
        </div>
    )
}

export default Logout