import MainMenu from "../../components/menu/MainMenu";

const About = () => {
  return (
    <div className="opener">
        <MainMenu />
      
      <div className="opener__logo-box">
        <a href="/">
        <img src="/img/BRAAPDoc_HD2.png" alt="Logo" className="opener__logo" />
        </a>
      </div>
      <div className=" opener__text-box opener__text-box--about">
        <img
          src="/img/dirt_bike_pure.jpg"
          alt="Person on Dirt Bike"
          width="50%"
          className="opener__about-img"
        />
        <div className="u-margin-bottom-small">
          <p>
            Hi and welcome to the About page of BraapDoc.com. My name is Tom
            Gruner and I am the creator of BraapDoc.com and the rider in the
            picture above.
          </p>

          <p>
            I was fascinated with motorcycles from an early age and always
            wanted to ride. I started at the age of 14 with a small 60cc Vespa
            style scooter.
          </p>

          <p>I rode various street bikes until I moved to Texas in 2016.</p>

          <p>
            Having sold my Aprilia RSV4 before the move, I had an empty space in
            my garage that needed to be filled. At this point I decided to ride
            in the dirt and give Dirt Bikes a go. I had never ridden in the dirt
            before but was hooked after my first ride.
          </p>

          <p>
            So, I went ahead and bought my friends 2008 KTM 250 XC-F. A quite
            capable dirt bike not only for beginners.
          </p>

          <p>
            A year or so later I sold the 250 and bought a 2017 KTM 450 XC-F,
            the bike in the picture. The picture was taken on my first ride out
            with the new (to me) bike.
          </p>

          <p>
            At this point it was clear to me that I would not return to riding
            on the street any time soon. I was and I still am hooked to riding
            in the dirt.
          </p>

          <p>
            In my professional career I'm a software developer and so I had the
            idea to combine my career skills and my hobby. That is when BraapDoc
            was born.
          </p>

          <p>
            Reading posts on social media and talking to friends and riding
            buddies, it became clear to me that many riders struggle with bike
            maintenance, simply because there are quite many tasks to track and
            take care of.
          </p>

          <p>
            In an effort to help my fellow riders and dirt enthusiasts I created
            BraapDoc with the intention to make a chore a little more fun and
            bearable.
          </p>

          <p>
            I believe that a little bit of maintenance and care can
            significantly increase the lifespan of our beloved dirt toys and
            decrease or even avoid expansive bills from professional workshops.
          </p>

          <p>
            If you agree with me on this, you've come to right place. Thanks for
            reading and for using BraapDoc. I hope you enjoy the app.
          </p>

          <p>
            I'd love to connect with you and hear from you on my social media
            pages.
          </p>

          <p>
            Find BraapDoc and connect with me on: Facebook, Instagram, Twitter,
            and YouTube
          </p>
        </div>
        <a href="/" className="btn btn--primary">Home</a>
      </div>
    </div>
  );
};

export default About;
